<template>
  <v-dialog max-width="68%" v-model="getPdfViewer.show" persistent>
    <v-card class="pa-2">
      <div style="position: absolute; align-self: end; right: 0">
        <v-btn icon @click="closeDialog" class="mr-5 mt-3">
          <v-icon color="red" x-large>mdi-window-close</v-icon>
        </v-btn>
      </div>
      <v-card-title class="justify-center headline">
        {{ getPdfViewer.name }}
      </v-card-title>
      <v-divider class="mb-5"></v-divider>
      <v-row no-gutters class="fill-height">
        <v-col align="center" cols="8">
          <div class="scroll" style="height: 570px;overflow-y: scroll;overflow-x: hidden">
            <pdf v-for="i in numOfPages" :key="i" :page="i" :src="pdfUrl" ref="pdf" width="100%" @error="errorOccured"
              class="">
              <canvas></canvas>
            </pdf>
          </div>
          {{ currentPdf + 1 }}/{{ numOfPdf }}
        </v-col>
        <v-col cols="1" align="center">
          <v-row no-gutters class="fill-height">
            <v-col cols="12">
              <v-divider vertical></v-divider>
            </v-col>
            <v-col cols="12" align-self="center" class="font-weight-bold">
              OR
            </v-col>
            <v-col cols="12">
              <v-divider vertical></v-divider>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3" align-self="center" align="center">
          <v-card flat width="300" height="320" class="containerBorder1">
            <v-card-actions class="justify-center pa-0 ma-0">
              <qrcode-vue :value="value" :size="size" level="H" class="px-10 pt-4" />
            </v-card-actions>
            <v-card-text class="font-weight-bold">SCAN THE QR CODE TO GET THE LINK</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" justify="end" align="end">
          <v-divider class="my-3"></v-divider>
          <v-btn width="200" color="primary" x-large class="ma-2 text-h5" @click="pickUp()">
            {{ $t('pickupbutton') }}
          </v-btn>
          <v-btn width="200" color="primary" x-large class="ma-2 text-h5" @click="sendEmail()">
            {{ $t('emailbutton') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import pdf from 'vue-pdf'
import { mapGetters } from 'vuex'
let config = require('./../../../config/config')
import QrcodeVue from 'qrcode.vue'
export default {
  name: 'pdfViewer',
  data() {
    return {
      loadingTask: null,
      numOfPages: null,
      pdfUrl: null,
      numOfPdf: 1,
      currentPdf: 0,
      value: '',
      size: 250
    }
  },
  components: {
    pdf,
    QrcodeVue
  },
  computed: {
    ...mapGetters(['getPdfViewer', 'getDefaultLocation', 'getMaps','getDefaultFormLocation'])
  },
  methods: {
    viewPdf(url) {
      this.pdfUrl = url;
      this.loadingTask = pdf.createLoadingTask(url)
      this.loadingTask.promise
        .then(pdf => {
          this.numOfPages = pdf.numPages;
        })
    },
    pdfCycle(direction) {
      if (direction === 'next') {
        if (this.currentPdf === this.numOfPdf - 1) this.currentPdf = 0;
        this.viewPdf(this.getPdfViewer.url[++this.currentPdf])
      } else {
        if (this.currentPdf === 0) this.currentPdf = this.numOfPdf - 1;
        this.viewPdf(this.getPdfViewer.url[--this.currentPdf])

      }

    },
    closeDialog() {
      this.numOfPages = null;
      this.numOfPdf = 1;
      this.pdfUrl = null;
      this.currentPdf = 0;
      this.$store.commit('closePdfViewer');
    },
    errorOccured() {
      console.log('Error while loading pdf')
    },
    pickUp() {
      let mapPath = this.getMaps.get(this.getDefaultFormLocation.toLowerCase())
      this.$store.dispatch('openImageViewer', {
        name: mapPath.mapName,
        url: mapPath.mapImage
      })
      this.$store.commit('closePdfViewer')
      this.$store.dispatch('avatarSpeak', this.$i18n.t('pickup'))
    },
    sendEmail() {
      console.log('this.getPdfViewer', this.getPdfViewer)
      this.$store.dispatch('openEmailViewer', {
        pdfType: this.getPdfViewer.type,
        pdfName: this.getPdfViewer.name,
        pdfUrl: this.getPdfViewer.emailLink
      })
      this.$store.commit('closePdfViewer')
    }
  },
  watch: {
    getPdfViewer(newVal) {
      console.log('PDF details:', this.getPdfViewer)
      if (newVal.show) {
        let speech = '';
        this.value = this.getPdfViewer.emailLink
        switch (this.getPdfViewer.type.toLowerCase()) {
          case 'pdf':
            this.viewPdf(this.getPdfViewer.url)
            speech = this.$i18n.t('form')
            break;
          case 'packets':
            this.viewPdf(this.getPdfViewer.url)
            //speech = this.getPdfViewer.desc + this.$i18n.t('packet')
            break;
          case 'guide':
            this.viewPdf(this.getPdfViewer.url)
            speech = this.$i18n.t('guide')
            break;
          default:
            break;
        }
        //this.$store.dispatch('avatarSpeak', speech)
      }
    }
  }
}
</script>
<i18n>
{
  "en": {
      "pickupbutton": "Pick Up",
      "emailbutton": "Email",
      "form": "You can pick-up this form for a fee, or I'll email you the form for free. Please select your choice.",
      "packet": "You can pick-up this packet for a fee, or I'll email you the packet for free. Please select your choice.",
      "guide": "You can pick-up this guide for a fee, or I'll email you the guide for free. Please select your choice.",
      "pickup": "Pick up this form for a fee at the Law Library, circled in red."
    },
  "es":{
      "pickupbutton": "Recoger",
      "emailbutton": "Email",
      "form": "Puede recoger este formulario por una tarifa o le enviaré el formulario por correo electrónico de forma gratuita. Por favor seleccione su elección.",
      "packet": "Puede recoger este paquete por una tarifa o le enviaré el paquete por correo electrónico de forma gratuita. Por favor seleccione su elección.",
      "guide": "Puede recoger esta guía por una tarifa o le enviaré la guía por correo electrónico de forma gratuita. Por favor seleccione su elección.",
      "pickup": "Recoja este formulario por una tarifa en la Law Library, encerrado en un círculo rojo."
    }
}
</i18n>

<style>
.scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  color: #d37444 !important;
  width: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px #d37444 !important;
}
</style>