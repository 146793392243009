<template>
    <v-dialog persistent max-width="67%" v-model="getTelepresenceViewer">
        <v-card class="pa-5">
            <v-card-title class="justify-center text-h4 pb-7 font-weight-bold ma-0"> {{ $t('header') }}
            </v-card-title>
            <div style="position: absolute; align-self: end; right: 0; top: 0">
                <v-btn icon class="mr-5 mt-3" @click="$store.commit('closeTelepresenceViewer')">
                    <v-icon color="red" x-large>mdi-window-close</v-icon>
                </v-btn>
            </div>
            <v-divider></v-divider>
            <v-card-text align="center" class="pa-0 mt-5">
                <v-card width="90%" height="60" color="primary" @click="showMap()"
                    class="white--text text-h5 my-1 justify-center align-center">
                    <v-card-text class="white--text text-h5"> {{ $t('option1') }} </v-card-text>
                </v-card>
                <!-- <v-card width="90%" height="60" color="primary" @click="initiateCall()"
                    class="white--text text-h5 my-1 justify-center align-center">
                    <v-card-text class="white--text text-h5"> {{ $t('option2') }} </v-card-text>
                </v-card> -->
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: "telepresenceViewer",
    computed: {
        ...mapGetters(['getTelepresenceViewer', 'getMaps', 'getDefaultLocation'])
    },
    methods: {
        showMap() {
            this.$store.commit('closeTelepresenceViewer')
            console.log('how are the maps: ', this.getDefaultLocation)
            var mapPath = this.getMaps.get(this.getDefaultLocation.toLowerCase())
            this.$store.dispatch('openImageViewer', {
                name: mapPath.mapName,
                url: mapPath.mapImage
            })
            this.$store.dispatch('avatarSpeak', mapPath.speech)
        },
        initiateCall() {
            this.$store.commit('closeTelepresenceViewer')
            this.$store.dispatch('initiateCall')
        }
    }
}
</script>
<i18n>
{
    "en": {
        "header": "Select an option you prefer",
        "option1": "Go to Court Administration Office",
        "option2": "Connect to live assistance"
      },
    "es":{
        "header": "Selecciona una opción que prefieras",
        "option1": "Ir a la Secretaría del Tribunal",
        "option2": "Conéctese a la asistencia en vivo"
      }
  }
</i18n>