import axios from 'axios'

import {authenticateStrapiUser} from './dataLoad.js'

const fs = require('fs')

export default {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        // async addQnaDatatoStrapi({ getters }, qnaData) {
        //     console.log(process.env.strapi_password,process.env.strapi_username,process.env.VUE_APP_I18N_LOCALE,"this is creds");
        //     const jwtToken = await  authenticateStrapiUser('adminofchester','1@#Backguard')

        //     // console.log(jwtToken,"this is the token needed");
        //     let config = {
        //         method: 'post',
        //         maxBodyLength: Infinity,
        //         url: 'https://cms.chester.arsconnect.com/qnas',
        //         headers: {
        //             'Content-Type': 'application/json',
        //             Authorization: `Bearer ${jwtToken}`,
        //         },
        //         data: qnaData
        //     };
        //     axios.request(config)
        //         .then((response) => {
        //             //console.log(JSON.stringify(response.data));
        //         })
        //         .catch((error) => {
        //             console.log(error);
        //         });
        // },
        async addSessionDataToStrapi({ getters }, sessionData) {

            // const jwtToken = await  authenticateStrapiUser('adminofchester','1@#Backguard')
            
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://cms.chester.arsconnect.com/user-sessions',
                headers: {
                    // 'Content-Type': 'application/json',
                    // Authorization: `Bearer ${jwtToken}`,
                },
                data: sessionData
            };
            axios.request(config)
                .then((response) => {
                    //console.log(JSON.stringify(response.data));
                    console.log("Session Data Added");
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }
}
