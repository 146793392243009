
module.exports = {
  firebase: {
    apiKey: "AIzaSyAFjemmQMYh3xjd2sx0JJMELkkVZa9nPEM",
    authDomain: "chester-pa-70c3b.firebaseapp.com",
    databaseURL: "https://chester-pa-70c3b-default-rtdb.firebaseio.com",
    projectId: "chester-pa-70c3b",
    storageBucket: "chester-pa-70c3b.appspot.com",
    messagingSenderId: "317553977294",
    appId: "1:317553977294:web:fb29885f50feeeb4d1ed9f",
    measurementId: "G-90QD0PZDEM"
  }
}

