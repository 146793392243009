<template>
    <v-dialog width="35%" v-model="getRequestFormViewer" persistent>
        <v-card flat>
            <v-card-title class="justify-center">
                <v-row class="mb-2">
                    <v-col cols="11" align="center">
                        <span class="text-h5 font-weight-bold">
                            Submit Enquiry</span>
                    </v-col>
                    <v-col cols="1" align="right">
                        <v-icon dark large color="secondary" @click="closeDialog()">mdi-close</v-icon>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                Sending To: <v-text-field v-model="courtEmail" outlined dense disabled></v-text-field>
                <v-divider></v-divider>
                <v-form class="mt-5" ref="sendRequestForm" lazy-validation>
                    <v-text-field v-model="userName" :rules="rules.nameRules" label="Enter your full Name" required outlined
                        dense></v-text-field>
                    <v-text-field v-model="userEmail" :rules="rules.emailRules" label="Enter your email" required outlined
                        dense></v-text-field>
                    <v-textarea label="Request" outlined v-model="userQuery" :rules="rules.queryRules"></v-textarea>
                    <v-btn block color="primary" @click="sendEmail()">Submit</v-btn>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: "requestFormViewer",
    data() {
        return {
            courtEmail: 'eplawlibrary@epcounty.com',
            userName: '',
            userEmail: '',
            userQuery: '',
            rules: {
                nameRules: [(v) => !!v || "Group name is required"],
                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ],
                queryRules: [(v) => !!v || "Request is required"]
            },
        }
    },
    computed: {
        ...mapGetters(['getRequestFormViewer','getKioskProfile','getSessionId'])
    },
    methods: {
        closeDialog(){
            this.$store.commit('setRequestFormViewer', false)
            this.$refs.sendRequestForm.reset()
        },
        sendEmail() {
            if (this.$refs.sendRequestForm.validate()) {
                this.$store
                    .dispatch("sendEmail", {
                        address: this.courtEmail,
                        subject: "New Request from " + this.userName,
                        content:
                            "Hello," +
                            "<br>" +
                            "<br>" + "Below is the request from the " + this.userName + ", <br><br>" +
                            this.userQuery +
                            "<br><br>" +
                            "Email id: " + this.userEmail +
                            "<br>" +
                            "<br> Thank you,",
                    })
                    .then(
                        (response) => {
                            console.log(response);
                            this.$store.commit("closeEmailViewer");
                            this.$store.dispatch(
                                "avatarSpeak",
                                this.$i18n.t("successemailresponse")
                            );
                            this.$refs.sendRequestForm.reset()
                            this.$store.commit('setRequestFormViewer', false)
                            // Touch usage
                            let date = new Date()
                            this.$store.dispatch('addSessionDataToStrapi', {
                applicationId: this.getKioskProfile.data().applicationId,
                actionType: 'Email',
                applicationSessionId: this.getSessionId,
                timeStamp: date.toISOString(),
                sessionPayload: {
                    module: "Request Form",
                    action: "User Query",
                    response: this.userQuery,
                    timeStamp: date.toISOString()
                }
            })
                        },
                        (error) => {
                            console.log(error);
                            this.$store.dispatch(
                                "avatarSpeak",
                                this.$i18n.t("erroremailresponse")
                            );
                            this.emailAddress = "";
                        }
                    );
            }
        }
    }
}
</script>
<i18n>
    {
      "en": {
          "sendbutton": "Send",
          "successemailresponse": "Got it! you will receive the email shortly.",
          "erroremailresponse": "Oops! There is some problem with email service. Try again later.",
          "noemail": "Email ID cannot be empty.",
          "invalidemail": "Please enter a valid Email ID."
        },
      "es":{
          "sendbutton": "Enviar",
          "successemailresponse": "¡Entiendo! recibirá el correo electrónico en breve.",
          "erroremailresponse": "Oops! Hay algún problema con el servicio de correo electrónico. Vuelve a intentarlo más tarde.",
          "noemail": "El ID de correo electrónico no puede estar vacío.",
          "invalidemail": "Ingrese un ID de correo electrónico válido."
        }
    }
    </i18n>