<template>
  <v-row no-gutters>
    <v-col v-if="showAvatar && getenableADA" class="d-flex mt-6 mb-3" align="center" justify="center" cols="12">
      <v-icon size="80" class="ml-10" @click="$router.push('/');
      backHome();
      " color="primary">
        mdi-arrow-left-circle
      </v-icon>
      <v-spacer></v-spacer>
      <span class="text-h3 ml-n10 font-weight-bold my-auto">
        {{ pageHeader }}
      </span>
      <v-spacer></v-spacer>
    </v-col>
    <v-col class="mr-5" v-if="showAvatar" cols="1">
      <avatar :initialWidth="190"></avatar>
    </v-col>
    <v-col class="ma-0 pa-0">
      <v-card :class="bubbleBorderClass" color="rgba(30, 101, 39, 0.88)" height="180">
        <v-row no-gutters class="fill-height ma-0 pa-0">
          <v-col class="px-5" align="center" align-self="center" cols="11">
            <span :class="{ 'text-h4': getTextBubbleText.length >= 200 }"
              class="white--text text-h3 font-weight-medium pl-5">
              {{ getTextBubbleText }}
            </span>
          </v-col>
          <v-col cols="1" align="center" align-self="center" v-if="!screenSaver">
            <v-row no-gutters v-if="getNetworkConnection" justify="center">
              <v-col v-if="!getIsThinking">
                <v-img class="talk-button" @click="actionOnTap()" contain width="140px"
                  :src="require('../assets/' + talkButtonSrc)">
                </v-img>
                <span class="white--text">
                  {{ speakingStatus }}
                </span>
              </v-col>
              <v-col v-else>
                <v-img contain width="140px" :src="require('../assets/' + talkButtonSrc)">
                </v-img>
                <span class="white--text">
                  {{ speakingStatus }}
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col v-if="showAvatar && !getenableADA" class="d-flex my-3" align="center" justify="center" cols="12">
      <v-icon size="80" class="ml-10" @click="$router.push('/');
      backHome();
      " color="primary">
        mdi-arrow-left-circle
      </v-icon>
      <v-spacer></v-spacer>
      <span class="text-h3 ml-n10 font-weight-bold my-auto">
        {{ pageHeader }}
      </span>
      <v-spacer></v-spacer>
    </v-col>
  </v-row>
</template>

<script>
import avatar from "@/components/avatar";
import { mapGetters } from "vuex";

export default {
  name: "appHeader",
  components: {
    avatar,
  },
  data() {
    return {
      showAvatar: false,
      pageHeader: "",
      changeFont: false,
      speakingStatus: "Tap To Talk",
      talkButtonSrc: "talkButton.png",
      count: 0
    };
  },
  computed: {
    ...mapGetters([
      "getTextBubbleText",
      "getPageHeader",
      "getIsSpeaking",
      "speechRecognitionStatus",
      "getQnaStatus",
      "getTouchCount",
      "isUserLooking",
      "getCurrentLanguage",
      "getenableADA",
      "screenSaver",
      "getKioskProfile",
      "getNetworkConnection",
      "getIsThinking",
      "getSessionId"
    ]),
    letterWiseText() {
      let text = '';
      let temp = this.getTextBubbleText.split(" ")
      temp.forEach(element => {
        let temp1 = element.split("")
        temp1.forEach(letter => {
          if (text === '') {
            text = letter
          } else {
            text = text + letter
          }
        })
      });
      return text
    },
    bubbleBorderClass() {
      if (this.getenableADA) {
        return 'bubbleBorder1'
      } else {
        return 'bubbleBorder'
      }
    }
  },
  watch: {
    $route(to, from) {
      if (to.path !== "/" && to.path !== "/screenSaver") {
        this.showAvatar = true;
      } else {
        this.showAvatar = false;
      }
    },
    getPageHeader(newVal) {
      this.pageHeader = newVal;
    },
    getIsSpeaking(newVal) {
      if (newVal) {
        this.talkButtonSrc = "stopTalking.png";
        this.speakingStatus = this.$i18n.t("isSpeaking");
      }
      if (!newVal && !this.speechRecognitionStatus) {
        this.talkButtonSrc = "talkButton.png";
        this.speakingStatus = this.$i18n.t("notSpeaking");
      }
    },
    speechRecognitionStatus(newVal) {
      if (newVal) {
        this.speakingStatus = this.$i18n.t("isListening");
        this.talkButtonSrc = "talkButtonActive.webp";
      } else {
        this.talkButtonSrc = "talkButton.png";
        this.speakingStatus = this.$i18n.t("notSpeaking");
      }
    },
    getQnaStatus(newVal) {
      if (newVal) {
        this.speakingStatus = "Thinking";
        this.talkButtonSrc = "talkButton.png";
      } else {
        this.talkButtonSrc = "talkButton.png";
        this.speakingStatus = this.$i18n.t("notSpeaking");
      }
    },
    getCurrentLanguage(newVal) {
      this.speakingStatus = this.$i18n.t("notSpeaking");
    },
  },
  methods: {
    actionOnTap() {
            let date = new Date()
            if (this.getTouchCount === 1) {
            this.$store.dispatch('addSessionDataToStrapi', {
                applicationId: this.getKioskProfile.data().applicationId,
                actionType: 'Touch',
                applicationSessionId: this.getSessionId,
                timeStamp: date.toISOString(),
                sessionPayload: {
                    module: "Default",
                    action: "Touch Detected",
                    response: "User Session Started",
                    timeStamp: date.toISOString()
                }
            })
            }
            console.log('Speaking Status: ', this.speakingStatus)
            if (this.speakingStatus === 'Speaking') {
                window.stopSpeech();
                this.$store.commit('setTextBubbleText', this.$i18n.t('bubbletextafteraction'));
            } else {
                if (this.$i18n.locale === "en") {
                    this.$store.commit("setSpeechRecognitionHandler", true);

                    // To start session using touch - when no face detected
                    if (!this.isUserLooking) {
                        console.log("User Session started");
                        this.$store.commit("setTouchCount", this.getTouchCount + 1);
                        if (this.getTouchCount === 1) {
                            // start time
                            this.$store.commit("setStartUserSession", new Date());
                        }
                    }
                } else {
                    if (!this.getIsSpeaking) {
                        this.$store.dispatch("avatarSpeak", "Sé hablar en español, pero todavía estoy aprendiendo a escuchar en español.");
                    } else {
                        window.stopSpeech();
                    }
                }
            }

        },
        backHome() {
            window.stopSpeech();
            // Touch Usage
            this.$store.commit('setTextBubbleText', this.$i18n.t('bubbletextafteraction'));
        }
  },
};
</script>

<style scoped>
.bubbleBorder {
  border-radius: 0 0 30px 30px !important;
}

.bubbleBorder1 {
  border-radius: 30px 30px 0 0 !important;
}

.talk-button {
  filter: sepia(19%) saturate(697%) hue-rotate(294deg) brightness(95%) contrast(350%);
}
</style>

<i18n>
{
  "en": {
      "bubbletextafteraction": "Please face towards the camera, tap on the microphone button, and ask a court-related question.",
      "isSpeaking": "Speaking",
      "notSpeaking": "Tap To Talk",
      "isListening": "Listening"
    },
  "es":{
      "bubbletextafteraction": "El reconocimiento de voz está desactivado para el idioma español.",
      "isSpeaking": "Discurso",
      "notSpeaking": "Toca para hablar",
      "isListening": "Escuchando"
    }
}
</i18n>
